import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import { useTranslation } from "react-i18next"
import LayoutForPageImage from "../components/Layouts/LayoutForPageImage/LayoutForPageImage"
import ImagePageStep8 from "../components/PageStep8/ImagePageStep8"
// import { Container } from 'react-bootstrap';
import LayoutTitle from "../components/Layouts/LayoutTitle/LayoutTitle"
import { Button  } from 'react-bootstrap'
// import Scheme8btn from "../components/PageStep8/Scheme8btn"
// import YouTubeButton from "../components/YouTubeButton/YouTubeButton"
import { Accordion, Card, Tabs, Tab, Alert } from "react-bootstrap"
import ImageScheme8en from "../components/PageStep8/ImageScheme8en"
import ImageScheme8ru from "../components/PageStep8/ImageScheme8ru"

const IndexPage = (props) => {
  const { t } = useTranslation()

  return (
    <div>
      <SEO title="How to solve a Rubik's cube   Как собрать кубик Рубика" />
      <LayoutTitle><h1 >{t('step8.h1')}</h1></LayoutTitle>
      
      <LayoutForPageImage>
        <ImagePageStep8 />
        <Accordion defaultActiveKey="0" style={{ margin: '0 auto', textAlign: 'center'}}>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="warning" style={{ display: 'block', margin: '0 auto', padding: "1rem", textAlign: 'center', letterSpacing: '1px'}} eventKey="1" >
              {t('nav.scheme')}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <Tabs defaultActiveKey={t('main.tab')}  transition={false} id="noanim-tab-example">
                  <Tab eventKey="home" title={t('main.tabtitleRu')} style={{marginTop: '1.5rem'}}>
                    <ImageScheme8ru />
                  </Tab>
                  <Tab eventKey="profile" title={t('main.tabtitleEng')} style={{marginTop: '1.5rem'}}>
                    <ImageScheme8en />
                  </Tab>
                </Tabs>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </LayoutForPageImage>
      {/* Video */}
      <div className="container-iframe">
        <iframe
          style={{ display: 'block', padding: '1rem 0', margin: '0 auto'}}
          width="90%"
          height="600"
          title={t("step8.videoTitle")}
          src={t("step8.videoUrl")}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen>
        </iframe>
      </div>
      <Alert  variant="dark" style={{textAlign: 'center'}}>
        {t("main.link_to_social_chat")}<span role="img" aria-label="hand">👉</span>
       <Alert.Link href={t("main.link_telegram_chat")} target="blank">{t("main.here")}</Alert.Link>
      </Alert> 

      {/* Buttons prev/next */}
      <div style={{textAlign: 'center', padding: '1rem'}}>
        <Link to="/step-7/">
          <Button variant="secondary">
            &#171;{t('step8.btnprev')}
          </Button>{' '}
        </Link>

        <Link to="/page-students/">
          <Button variant="secondary">
            {t('step8.btnnext')}&#187;
          </Button>{' '}
        </Link>
      </div>
    </div>
  )
}
export default IndexPage